body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.egyen-main-panel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.egyen-tablazat-panel-phd {
  min-width: 64em;
  width: 64em;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0.5em;
}

.egyen-tablazat-panel {
  min-width: 40em;
  width: 40em;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 0.5em;
}

.egyen-tablazat td, th {
  padding: 0.25em 1em 0.25em 1em;
}

.egyen-tablazat td {
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 1px dotted black;
  border-bottom: 1px dotted black;
}

.egyen-summary td {
  border-top: 1px solid black;
  border-bottom: none;
  font-weight: bold;
}

.egyen-tablazat {
  border-spacing: 0;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  margin-bottom: 1em;
}

.egyen-plot-panel {
  width: 45em;
  min-width: 40em;
  max-width: 70em;
  min-height: 32em;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 1em;
  margin-bottom: 0;
  display: grid;
  grid-template-rows: minmax(0,1fr) minmax(0,1fr);
  grid-template-columns: minmax(0,1fr) minmax(0,1fr);  
}

.egyen-plot-panel-single {
  min-height: 18em;
  width: 20em;
  min-width: 20em;
  max-width: 40em;
  flex-grow: 1;
  flex-shrink: 1;
  margin: 1em 2em 0em 1em;  
  display: grid;
  grid-template-rows: minmax(0,1fr);
  grid-template-columns: minmax(0,1fr);  
}


@media print {
.egyen-plot-panel {
  width: 45em;
  height: 24em;
}
}

.egyen-plot {
/*  width: 100%;
  height: 100%;*/
}

.popover {
  max-height:300px;
  overflow:auto
}

.egyen-kereso {
  margin: 2em 20% 0.5em 20%;
}

.valtozasok {
  margin: 2em 20% 0.5em 20%;
  text-align: left;
}


.egyen-main-progress {
  margin: 0 20%;
}

.egyen-titulusok h2 {
  margin: 0 0 0.75em 0;
  font-size: 1.1em;
  font-weight: normal;
  font-style: italic;
}

.egyen-titulusok h3 {
  margin: 0 0 1em 0;
  font-size: 1.1em;
  font-weight: normal;
}

.warnings {
  margin: 0 0 0.5em 0;
}

.warning {
  font-size: 1.1em;  
  margin-bottom: 0.5em;
  font-weight: bolder;
  color: red;
}

.egyen-titulusok h1 {
  margin: 0.5em 0 0.25em 0;
}

.egyen-kereso-felsosor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap
}

.egyen-kereso-elem {
  margin: 0.5em 0.5em;
  min-width: 10em;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  display: flex;
  flex-direction: row;
  position: relative;
}

.egyen-kereso-behuzott {
  margin-left: 2.25em;
}

.egyen-kereso-elem span:first-child {
  margin: auto;
}

.egyen-kereso-elem .egyen-kereso-input {
  min-width: 1em;
  flex-grow: 1;  
  flex-basis: 0;
}

.egyen-kereso-input .bp3-popover-target {
  width: 100%;
}

.egyen-kereso-elem-cimke {
  flex-grow: 0;
}

.egyen-kereso-datum {
  text-align: left;
  margin: 0.5em 0.5em;
}

.scoring-button {
  margin-top: 1em;
}

.row-label {
  text-align: left;
}

.bold-text {
  font-weight: bold;
}

.eloterjesztoi-tabla {
  border-collapse: collapse;
  border: 1px solid black;
  margin: auto;
}

.eloterjesztoi-tabla tr td {
  border: 1px solid black;
}

.eloterjesztoi-tabla tr th {
  border: 1px solid black;
  background-color: lightblue;
}

.main-row {
  background-color: lightgoldenrodyellow;
}

.pontozo-tabla {
  border-collapse: collapse;
  border: 1px solid black;
  margin: auto;
}

.pontozo-tabla tr td {
  border: 1px solid black;
}

.pontozo-tabla tr th {
  border: 1px solid black;
  background-color: lightblue;
}

.norveg-0 {
  background-color: #FFCDD2;
}

.norveg-x {
  background-color: #FFECB3;
}

.norveg-2 {
  background-color: #DCEDC8;
}

.comment {
  margin: 0.5em 20% 1em 20%;
  font-style: italic;
}

.mtmtspinner {
  margin-top: 1em;
}

.mtmtspinner p {
  margin-top: 0.5em;
}

.expander-header {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-items: center;
  margin-left: 0.25em;
}

.expander-header-right {
  padding-right: 0.3em;
}

.expander-header-right.expander-header-open .bp3-button.bp3-minimal.bp3-small .bp3-icon.bp3-icon-caret-right {
  transform: rotate(90deg);
  transition: 0.3s;
}

.expander-header-right.expander-header-closed .bp3-button.bp3-minimal.bp3-small .bp3-icon.bp3-icon-caret-right {
  transform: rotate(0deg);
  transition: 0.3s;
}

.expander-header-title {
  display: flex;
  align-items: center;
}

.advanced-mto-panel {
  text-align: left;
  margin-left: 2.25em;
  margin-top: 0.5em;
}

.failed {
  color: red; 
  font-weight: bold;
}

.passed {
  color: green; 
  font-weight: bold;
}

@media all {
  .warning-detail {
    display: none;
  }
}

@media print {
  .egyen-kereso {
    display: none;
  }
  .warning-detail {
    display: block;
  }
}

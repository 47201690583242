body {
  background-color: ghostwhite;
  position: relative;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 90%;
  height: 100%;
  min-height: 100vh;
  min-width: 55em;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.App-header {
  background-color: #173d7a;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: white;
  padding: 1em;
}

.App-title {
  font-size: 4em;  
  font-family: "Open Sans";
  font-weight: normal;
  margin-top: 0.25em;
  margin-bottom: 0;
}

.App-menu .bp3-tab-list {
  background-color: #173d7a;
  padding: 0.5em;
}

.App-menu .bp3-tab-indicator-wrapper .bp3-tab-indicator {
  background-color: white;
}

.App-menu #bp3-tab-title_undefined_mtascr {
  color: white;
}

.App-menu #bp3-tab-title_undefined_phdscr {
  color: white;
}

.App-menu #bp3-tab-title_undefined_dept {
  color: white;
}

.App-menu #bp3-tab-title_undefined_fact {
  color: white;
}

.App-menu-row {
    background-color: #173d7a;
    text-align: left;
    color: white;
    font-size: 1.15em;
    padding: 0.5em;
}

.App-menu-item {
    display: inline-block;
    padding: 0.4em 0 0.4em 0em;
    margin-right: 1em;
    color: white;
    text-decoration: none;
}

.App-menu-item-active {
  border-bottom: 3px solid white;
}

.App-menu-item:visited, .App-menu-item:hover, .App-menu-item .bp3-icon {
  color: white;
  text-decoration: none;
}

@media print {
  .App-menu-row, .App-header {
    display: none;
  }
  .App {
    border: none;
  }
}

@media all {
  .page-break { display: none; }
  .print-only { display: none; }
}
  
@media print {
  .page-break { display: block; page-break-before: always; }
  .screen-only { display: none; }
  .print-only { display: block; }
}
